import axios from "axios";
import { WorkstreamConstants, WorkstreamConstantsValidator } from "./workstream-constant/workstream-constant";

export const getWorkstreamConstants = async (
  category?: string,
  key?: string
): Promise<WorkstreamConstants> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<WorkstreamConstants>(
    `${REACT_APP_DOMAIN}/api/v2/workstream_constants`,
    {
      params: {
        category: category,
        key: key
      }
    }
  );

  WorkstreamConstantsValidator.check(result.data);

  return result.data;
};