import React from "react";
import TooltipContent from "common-components/tooltip-content/TooltipContent";
import { AdvancedTooltip } from "api";
import "./AdvancedTooltipContent.scss";
import classNames from "classnames";
import AdvancedTooltipContentMapper from "./AdvancedTooltipContentMapper";
import CloseIcon from "icons/close-icon";

type Props = {
  tooltip: AdvancedTooltip;
  className?: string;
  width?: "small" | "normal";
  closeIcon?: boolean;
  onClose?: () => void;
};

const namespace = "rts-pa-advanced-tooltip-content";

export const AdvancedTooltipContent = (props: Props) => {
  const className = classNames(namespace, props.className);

  return (
    <TooltipContent
      className={props.width === "small" ? `${className}-small` : className}
    >
      <div className={`${namespace}-content-container`}>
        <div className={`${namespace}-left-content-container`}>
          {props.tooltip.sections.map((section, i) => {
            const sectionClassName = classNames(`${namespace}-section`, {
              [`${section.rowSpacing}-row-gap`]: section.rowSpacing
            });

            return (
              <div key={i} className={sectionClassName}>
                {section.title && (
                  <TooltipContent.Gray>{section.title}</TooltipContent.Gray>
                )}
                {section.rows.map((row, i) => (
                  <div key={i} className={`${namespace}-section-row`}>
                    {row.label && (
                      <TooltipContent.Bold>{`${row.label} `}</TooltipContent.Bold>
                    )}
                    {row.content.map((content, i) => {
                      return (
                        <AdvancedTooltipContentMapper
                          key={i}
                          id={i}
                          content={content}
                        />
                      );
                    })}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <div>
          {props.closeIcon ? (
            <CloseIcon
              className={`${namespace}-close-icon`}
              onClick={props.onClose}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </TooltipContent>
  );
};

export default AdvancedTooltipContent;
