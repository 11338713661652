import React from "react";

type Props = {
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
  color?: string;
};

export const CloseIcon = (props: Props) => {
  const {
    width = 10,
    height = 10,
    onClick,
    className,
    color = "#8C8C8C"
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M6.17593 4.99999L9.75926 1.42499C9.91618 1.26807 10.0043 1.05524 10.0043 0.833325C10.0043 0.611407 9.91618 0.398578 9.75926 0.241658C9.60234 0.0847387 9.38951 -0.00341797 9.16759 -0.00341797C8.94567 -0.00341797 8.73285 0.0847387 8.57593 0.241658L5.00093 3.82499L1.42593 0.241658C1.26901 0.0847387 1.05618 -0.00341797 0.834259 -0.00341797C0.612341 -0.00341797 0.399512 0.0847387 0.242592 0.241658C0.0856726 0.398578 -0.00248408 0.611407 -0.00248408 0.833325C-0.00248408 1.05524 0.0856726 1.26807 0.242592 1.42499L3.82593 4.99999L0.242592 8.57499C0.164485 8.65246 0.10249 8.74463 0.060183 8.84618C0.0178758 8.94773 -0.00390625 9.05665 -0.00390625 9.16666C-0.00390625 9.27667 0.0178758 9.38559 0.060183 9.48714C0.10249 9.58869 0.164485 9.68086 0.242592 9.75832C0.320061 9.83643 0.412229 9.89843 0.513778 9.94074C0.615328 9.98304 0.724249 10.0048 0.834259 10.0048C0.944269 10.0048 1.05319 9.98304 1.15474 9.94074C1.25629 9.89843 1.34846 9.83643 1.42593 9.75832L5.00093 6.17499L8.57593 9.75832C8.6534 9.83643 8.74556 9.89843 8.84711 9.94074C8.94866 9.98304 9.05758 10.0048 9.16759 10.0048C9.2776 10.0048 9.38652 9.98304 9.48807 9.94074C9.58962 9.89843 9.68179 9.83643 9.75926 9.75832C9.83737 9.68086 9.89936 9.58869 9.94167 9.48714C9.98398 9.38559 10.0058 9.27667 10.0058 9.16666C10.0058 9.05665 9.98398 8.94773 9.94167 8.84618C9.89936 8.74463 9.83737 8.65246 9.75926 8.57499L6.17593 4.99999Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
