import { Array, Boolean, Number, Record, Static, String, Undefined } from "runtypes";
import { AdEntityValidator } from "api/common/ad-entity";
import { MilestoneCalendarKeysResponseValidator } from "api/milestone-calendar-key/milestone-calendar-key-common";

export const CalendarValidator = Record({
  id: Number,
  title: String,
  publishedDate: String,
  owner: AdEntityValidator,
  isHiddenFromSuperAdmin: Boolean,
  admins: Array(AdEntityValidator),
  fullViewers: Array(AdEntityValidator),
  limitedViewers: Array(AdEntityValidator),
  milestoneCalendarKeys: MilestoneCalendarKeysResponseValidator,
  defaultDateRange: Array(String).Or(Array(Undefined))
});

export type Calendar = Static<typeof CalendarValidator>;
