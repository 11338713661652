import React from "react";
import "./ComModalPipelineProject.scss";
import { CommercialPipelineProject } from "api";
import { AccordionWithStatus } from "common-components/accordion-with-status/AccordionWithStatus";
import { InactiveSection } from "api/common";
import getAdvancedTooltipContent from "common-components/advanced-tooltip/getAdvancedTooltipContent";
import PipelineProject from "components/pipeline-project/PipelineProject";

type Props = {
  project: CommercialPipelineProject | InactiveSection | null;
};

const ComModalPipelineProject = (props: Props) => {
  if (!props.project) {
    return <></>;
  } else if (props.project.status === "inactive") {
    return <AccordionWithStatus title={props.project.title} status="gray" />;
  }

  return (
    <AccordionWithStatus
      status={props.project.status}
      title={props.project.title}
      className={`rts-pa-commercial-modal-pipeline-project ${props.project.title}`}
      accordionSyncKey="CommercialModal"
      links={props.project.externalLinks}
      tooltipContent={getAdvancedTooltipContent(props.project.tooltip)}
    >
      <PipelineProject
        owners={props.project.owners}
        hideOwnersOnEmpty={true}
        nextApprovalLaunchE2l={
          props.project.nextApprovalLaunchE2l
            ? props.project.nextApprovalLaunchE2l
            : undefined
        }
        nextPriorityMilestone={
          props.project.nextPriorityMilestone
            ? props.project.nextPriorityMilestone
            : undefined
        }
        studyEnrollments={props.project.studyEnrollments}
      />
    </AccordionWithStatus>
  );
};

export default ComModalPipelineProject;
