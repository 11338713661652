import {
  CircleIcon,
  ThreeQuarterCircleIcon,
  TriangleIcon,
  SquareIcon,
  DiamondIcon,
  PentagonIcon,
  HeptagramIcon,
  DashIcon,
  BarIcon
} from "icons/key-events-icons";
import React, { useMemo } from "react";
import "./MilestoneTypeIcon.scss";
import { Text } from "@opsdti-global-component-library/amgen-design-system";
import { KeyEventsIconProps } from "icons/key-events-icons/common-props";
import { getMilestoneCalendarKeys } from "api";
import { useQuery } from "@tanstack/react-query";

const namespace = "rts-pa-milestone-type-icon";

type Props = {
  id?: number;
  showLabel?: boolean;
  className?: string;
  size?: "large" | "small";
  icon?:
    | "circle"
    | "triangle"
    | "square"
    | "eclipse"
    | "pentagon"
    | "diamond"
    | "heptagram"
    | "dash"
    | "bar";
  color?: string;
};

export const MilestoneTypeIcon = (props: Props) => {
  const { data } = useQuery(["calendar-keys"], getMilestoneCalendarKeys, {
    enabled: false
  });

  const milestoneCalendarKey = useMemo(() => {
    return data?.keys.find(mck => mck.id === props.id);
  }, [props.id, data]);

  if (!milestoneCalendarKey && !props.icon) {
    return null;
  }

  let Icon: (props: KeyEventsIconProps) => React.JSX.Element;

  switch (milestoneCalendarKey?.icon || props.icon) {
    case "circle":
      Icon = CircleIcon;
      break;
    case "eclipse":
      Icon = ThreeQuarterCircleIcon;
      break;
    case "triangle":
      Icon = TriangleIcon;
      break;
    case "square":
      Icon = SquareIcon;
      break;
    case "diamond":
      Icon = DiamondIcon;
      break;
    case "pentagon":
      Icon = PentagonIcon;
      break;
    case "heptagram":
      Icon = HeptagramIcon;
      break;
    case "dash":
      Icon = DashIcon;
      break;
    case "bar":
      Icon = BarIcon;
      break;
    default:
      Icon = CircleIcon;
  }

  return (
    <span className={namespace}>
      <Icon
        width={props.size === "large" ? 14 : 8}
        height={props.size === "large" ? 14 : 8}
        className={props.className}
        fill={milestoneCalendarKey?.color || props.color}
      />
      {props.showLabel && (
        <Text className="rts-pa-key-events-page-icon-label">
          {milestoneCalendarKey?.label}
        </Text>
      )}
    </span>
  );
};

export default MilestoneTypeIcon;
