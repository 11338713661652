import React from "react";
import "./AdvancedTooltipContentMapper.scss";
import StatusIcon from "common-components/status-icon/StatusIcon";
import { AdvancedTooltipContent } from "api/common";
import { Button, Label, Text } from "common-components";
import {
  CaretRightIcon,
  LinkExternalIcon
} from "@opsdti-global-component-library/amgen-design-system";

type Props = {
  id: string | number;
  content: AdvancedTooltipContent;
};

const namespace = "rts-pa-advanced-tooltip-content-mapper";

const AdvancedTooltipContentMapper = (props: Props) => {
  if (typeof props.content === "string") {
    return <Text key={props.id}>{props.content}</Text>;
  }

  switch (props.content.type) {
    case "text":
      return (
        <Text key={props.id} style={props.content.style || undefined}>
          {props.content.value}
        </Text>
      );
    case "icon":
      switch (props.content.icon) {
        case "accelerated":
        case "green":
        case "yellow":
        case "red":
        case "gray":
          return <StatusIcon key={props.id} status={props.content.icon} />;
        default:
          return <></>;
      }
    case "icon-definition": {
      const iconDefinitionNamespace = `${namespace}-icon-definition`;
      return (
        <div
          key={`icon-${props.id}`}
          className={`${iconDefinitionNamespace}-container`}
        >
          <div className={`${iconDefinitionNamespace}-icon-col`}>
            <AdvancedTooltipContentMapper
              key={`icon-${props.id}`}
              id={props.id}
              content={{ type: "icon", icon: props.content.icon }}
            />
          </div>
          <div className={`${iconDefinitionNamespace}-description-col`}>
            {props.content.definition.map((content, i) => (
              <AdvancedTooltipContentMapper
                key={i}
                id={props.id}
                content={content}
              />
            ))}
          </div>
        </div>
      );
    }
    case "column-2": {
      const typeNamespace = `${namespace}-column-2`;
      return (
        <div key={props.id} className={`${typeNamespace}-container`}>
          {props.content.rows.map((r, j) => (
            <div key={j} className={`${typeNamespace}-row`}>
              <Label className={`${typeNamespace}-row-label`}>
                {r.leftContent}
              </Label>
              <Text strong className={`${typeNamespace}-row-value`}>
                {r.rightContent}
              </Text>
            </div>
          ))}
        </div>
      );
    }
    case "space": {
      let text = "";
      for (let i = 0; i < props.content.spaces; i++) {
        text += "\u00A0";
      }
      return <span key={props.id}>{text}</span>;
    }
    case "link": {
      return (
        <Button
          target="_blank"
          className={`${namespace}-button`}
          href={props.content.href}
          text={props.content.label}
          type="link"
          icon={
            props.content.isSensingExternal ? (
              <LinkExternalIcon
                className={`${namespace}-button-icon`}
                width={12}
                height={12}
              />
            ) : (
              <CaretRightIcon
                className={`${namespace}-button-icon`}
                width={20}
                height={20}
              />
            )
          }
        />
      );
    }
    case "date-range": {
      const typeNamespace = `${namespace}-column-2`;
      return (
        <div key={props.id} className={`${typeNamespace}-container`}>
          <div className={`${typeNamespace}-date-row`}>
            <div>
              <Text strong>Start Date:</Text>
              <Text>{" " + props.content.startDate}</Text>
            </div>
            <div>
              <Text strong>End Date:</Text>
              <Text>{" " + props.content.endDate}</Text>
            </div>
          </div>
        </div>
      );
    }
    default:
      return <></>;
  }
};

export default AdvancedTooltipContentMapper;
