import React, { useContext } from "react";
import { BaseActivityLog } from "../base-activity-log/BaseActivityLog";
import {
  ActivityLogDataPayload,
  getPaActivityLogData,
  getPaActivityLogDropdownValuesData
} from "api/activity-log";
import { PermissionsContext } from "global/permissions";
import { getAgendaId } from "utils/multitenancy";
import pages from "pages/pages";

export const PrioritizedAgendaActivityLog = () => {
  const perms = useContext(PermissionsContext);

  const workstream = "Prioritized Agenda";
  const activityType = "DynamicPA";
  const entityId = getAgendaId();
  const namespace = "rts-pa-activity-log";
  const hasPerms =
    entityId === "1" && perms.grid.activityLog && perms.grid.status;
  const defaultActivityLogFilters: ActivityLogDataPayload = {
    workstream: workstream,
    activityType: activityType,
    filterList: [
      { filterKey: "item_id", filterValue: "All" },
      { filterKey: "status", filterValue: "All" },
      { filterKey: "timeline", filterValue: "Last 60 Days" }
    ]
  };

  return (
    <BaseActivityLog
      defaultCollapsed={true}
      workstream={workstream}
      activityType={activityType}
      namespace={namespace}
      hasPerms={hasPerms}
      defaultActivityLogFilters={defaultActivityLogFilters}
      getDataFn={getPaActivityLogData}
      getDropdownDataFn={getPaActivityLogDropdownValuesData}
      isLimitedViewPage={pages.grid}
    />
  );
};
