import { Switch } from "@opsdti-global-component-library/amgen-design-system";
import {
  Button,
  Checkbox,
  DatePicker,
  DateRangePicker,
  Input,
  LinksInput,
  Select,
  SelectSimple,
  TextArea
} from "common-components";
import React, { useMemo, useState } from "react";
import "./UpsertCalendarRowModalMilestoneFields.scss";
import { useValidator } from "global/use-validator";
import { getMilestoneCalendarKeys } from "api";
import { useQuery } from "@tanstack/react-query";
import {
  AutoUpdater,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import produce from "immer";
import { CalendarMilestone } from "api/calendar/calendar-common";

const namespace = "rts-pa-upsert-calendar-row-item-modal-milestone-fields";

type Props<T extends AutoUpdaterType> = {
  autoUpdater: AutoUpdater<T>;
  onDeleteClick?: () => void;
};

type SelectOptions = {
  label: string;
  value: number;
};

type TypeOptions = "exact" | "range";

const UpsertCalendarRowModalMilestoneFields = <T extends AutoUpdaterType>(
  props: Props<T>
) => {
  const { value: milestone, onChange } = useAutoUpdater<CalendarMilestone, T>(
    props.autoUpdater
  );

  const { fieldUtils } = useValidator();

  const { data: apiCalendarKeys, status: calendarKeysStatus } = useQuery(
    ["calendar-keys"],
    getMilestoneCalendarKeys, {enabled: false}
  );

  const calendarKeyOptions = useMemo(() => {
    const keys: SelectOptions[] = [];

    if (apiCalendarKeys) {
      apiCalendarKeys.keys.forEach(key => {
        keys.push({ label: key.label, value: key.id });
      });
    }

    return keys;
  }, [apiCalendarKeys]);

  const [milestoneType, setMilestoneType] = useState<TypeOptions>(
    (
      props.autoUpdater.propExpression(
        props.autoUpdater.item
      ) as CalendarMilestone
    ).endDate
      ? "range"
      : "exact"
  );

  const milestoneTypeOptions: { label: string; value: TypeOptions }[] =
    useMemo(() => {
      return [
        { label: "Exact Milestone", value: "exact" },
        { label: "End-to-End Milestone", value: "range" }
      ];
    }, []);

  const onSwitch = () => {
    const newMilestone = produce(milestone, draftState => {
      draftState.isVisibleToOfficers = !milestone.isVisibleToOfficers;
    });
    onChange(newMilestone);
  };

  return (
    <div className={namespace}>
      <Input
        label="Title"
        autoUpdater={{
          item: props.autoUpdater.item,
          setItem: props.autoUpdater.setItem,
          propExpression: ri =>
            (props.autoUpdater.propExpression(ri) as CalendarMilestone).name
        }}
        required
        validator={fieldUtils}
      />
      <SelectSimple
        label="Type"
        options={milestoneTypeOptions}
        value={milestoneType}
        required
        onChange={value => {
          if (value === "exact") {
            // for "exact", null out endDate
            const newItem = produce(props.autoUpdater.item, draftState => {
              const newMilestone = props.autoUpdater.propExpression(
                draftState as T
              ) as CalendarMilestone;

              newMilestone.endDate = null;
            });

            props.autoUpdater.setItem(newItem);
          } else if (value === "range") {
            // for "range", set calendar key to
            const newItem = produce(props.autoUpdater.item, draftState => {
              const newMilestone = props.autoUpdater.propExpression(
                draftState as T
              ) as CalendarMilestone;

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              newMilestone.milestoneCalendarKeyId = apiCalendarKeys!.keys.find(
                k => k.type === "end_to_end_milestone"
              )!.id;
            });

            props.autoUpdater.setItem(newItem);
          }

          setMilestoneType(value);
        }}
        disabled={milestone.type === "automated"}
      />
      <div className={`${namespace}-date-fields-container`}>
        {milestoneType === "exact" ? (
          <DatePicker
            label="Date"
            autoUpdater={{
              item: props.autoUpdater.item,
              setItem: props.autoUpdater.setItem,
              propExpression: ri =>
                (props.autoUpdater.propExpression(ri) as CalendarMilestone)
                  .startDate
            }}
            required
            disabled={!props.onDeleteClick}
          />
        ) : (
          <DateRangePicker
            label="Date Range"
            autoUpdater={{
              item: props.autoUpdater.item,
              setItem: props.autoUpdater.setItem,
              propExpressionStartDate: ri =>
                (props.autoUpdater.propExpression(ri) as CalendarMilestone)
                  .startDate,
              propExpressionEndDate: ri =>
                (props.autoUpdater.propExpression(ri) as CalendarMilestone)
                  .endDate
            }}
            required
            disabled={!props.onDeleteClick}
          />
        )}
        <Checkbox
          label="Hide Date"
          className={`${namespace}-checkbox-middle-align`}
          autoUpdater={{
            item: props.autoUpdater.item,
            setItem: props.autoUpdater.setItem,
            propExpression: ri =>
              (props.autoUpdater.propExpression(ri) as CalendarMilestone)
                .hideDate
          }}
        />
      </div>
      {milestoneType === "exact" && (
        <Select
          label="Calendar Key"
          autoUpdater={{
            item: props.autoUpdater.item,
            setItem: props.autoUpdater.setItem,
            propExpression: ri =>
              (props.autoUpdater.propExpression(ri) as CalendarMilestone)
                .milestoneCalendarKeyId
          }}
          required
          validator={fieldUtils}
          options={calendarKeyOptions}
          loading={calendarKeysStatus === "loading"}
        />
      )}
      <LinksInput
        title="External Links"
        autoUpdater={{
          item: props.autoUpdater.item,
          setItem: props.autoUpdater.setItem,
          propExpression: ri => {
            const links = (
              props.autoUpdater.propExpression(ri) as CalendarMilestone
            ).links;
            return links;
          }
        }}
        validator={fieldUtils}
        labelMaxLength={30}
      />
      <TextArea
        label="Additional Info"
        autoUpdater={{
          item: props.autoUpdater.item,
          setItem: props.autoUpdater.setItem,
          propExpression: ri =>
            (props.autoUpdater.propExpression(ri) as CalendarMilestone).text
        }}
        disabled={milestone.type === "automated"}
      />
      <div className={`${namespace}-switch-button-container`}>
        <Switch
          checked={milestone.isVisibleToOfficers}
          onChange={onSwitch}
          inlineText="Show for Limited Viewers"
        />
        {props.onDeleteClick && (
          <Button
            text="Delete"
            type="secondary"
            danger={true}
            onClick={props.onDeleteClick}
          />
        )}
      </div>
    </div>
  );
};

export default UpsertCalendarRowModalMilestoneFields;
