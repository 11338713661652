import React, { useEffect, useMemo } from "react";
import "./DateRangePicker.scss";
import {
  RangePicker,
  RangePickerProps
} from "@opsdti-global-component-library/amgen-design-system";
import dayjs, { Dayjs } from "dayjs";
import { Label } from "common-components";
import {
  AutoUpdaterPropExpression,
  AutoUpdaterSetItem,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { AdvancedTooltip } from "api/common";

export type Props<T extends AutoUpdaterType> = {
  label: string;
  autoUpdater: {
    item: T;
    setItem: AutoUpdaterSetItem<T>;
    propExpressionStartDate: AutoUpdaterPropExpression<T>;
    propExpressionEndDate: AutoUpdaterPropExpression<T>;
  };
  required?: boolean;
  tooltip?: AdvancedTooltip;
} & Omit<RangePickerProps, "onChange">;

const namespace = "rts-pa-date-range-picker";

export const DateRangePicker = <T extends AutoUpdaterType>({
  label,
  autoUpdater,
  ...props
}: Props<T>) => {
  const { value: startDate, onChange: onChangeStartDate } = useAutoUpdater<
    string,
    T
  >({
    item: autoUpdater.item,
    setItem: autoUpdater.setItem,
    propExpression: autoUpdater.propExpressionStartDate
  });

  const { value: endDate, onChange: onChangeEndDate } = useAutoUpdater<
    string,
    T
  >({
    item: autoUpdater.item,
    setItem: autoUpdater.setItem,
    propExpression: autoUpdater.propExpressionEndDate
  });

  const value: [Dayjs, Dayjs] | undefined = useMemo(() => {
    if (!startDate || !endDate) {
      return undefined;
    }

    return [dayjs.tz(startDate), dayjs.tz(endDate)];
  }, [startDate, endDate]);

  // default-set values if field is required
  useEffect(() => {
    if (!props.required) {
      return;
    }

    if (!startDate) {
      onChangeStartDate(dayjs().format("YYYY-MM-DD"));
    }

    if (!endDate) {
      onChangeEndDate(dayjs().format("YYYY-MM-DD"));
    }
  }, [props.required]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={namespace}>
      <Label tooltip={props.tooltip}>
        {label}
      </Label>
      <RangePicker
        {...props}
        value={value}
        onChange={dates => {
          if (!dates || !dates[0] || !dates[1]) {
            const newItem = onChangeStartDate(null as unknown as string);
            onChangeEndDate(null as unknown as string, newItem);
            return;
          }

          const newItem = onChangeStartDate(dates[0].format("YYYY-MM-DD"));
          onChangeEndDate(dates[1].format("YYYY-MM-DD"), newItem);
        }}
        allowClear={!props.required}
      />
    </div>
  );
};

export default DateRangePicker;
