import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  getAgendaItems,
  postImportedAgendaItems,
  ImportedAgendaItems
} from "api";
import PaModal from "common-components/pa-modal/PaModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import "./ImportAgendaItemModal.scss";
import { Button } from "@opsdti-global-component-library/amgen-design-system";
import { useValidator } from "global/use-validator";
import produce from "immer";
import { useStatusManager } from "global/use-status-manager";
import { useNavigate, useSearchParams } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import { useModalHelper } from "global/use-modal-helper";
import { Select } from "common-components";

const initialItem: ImportedAgendaItems = {
  value: 1,
  focus: 1,
  agendaItemIds: []
};

const namespace = "rts-pa-import-agenda-item-modal";

const ImportAgendaItemModal = () => {
  const hasPermissions = useContext(PermissionsContext).grid.edit;
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    [pages.grid.edit.importItemModal.path],
    hasPermissions
  );
  const [importedAgendaItems, setImportedAgendaItems] =
    useState<ImportedAgendaItems>(initialItem);

  const [searchParams] = useSearchParams();

  const params = useMemo(() => {
    if (mhResult.path !== pages.grid.edit.importItemModal.path) {
      return { focus: 1, value: 1 };
    }

    let focus = parseInt(searchParams.get("focus") || "");
    let value = parseInt(searchParams.get("value") || "");

    if (!(1 <= focus && focus <= 3)) {
      focus = 1;
    }

    if (!(1 <= value && value <= 3)) {
      value = 1;
    }

    return {
      focus: focus,
      value: value
    };
  }, [searchParams, mhResult]);

  const { containerUtils, fieldUtils } = useValidator();

  const { data: agendaItems, refetch: refreshGrid } = useQuery(
    ["agenda-items", false],
    () => getAgendaItems(false)
  );

  const {
    data: apiCeoStaffPaItems,
    status: apiCeoStaffPaItemsStatus,
    error
  } = useQuery(["ceo-staff-agenda-items"], () => getAgendaItems(false, "1"), {
    enabled: mhResult.enabled
  });

  const CEOStaffPaItems = useMemo(() => {
    if (!apiCeoStaffPaItems) {
      return [];
    }

    const agendaItemsIds = agendaItems?.groups.flatMap(group =>
      group.items.map(item => item.id)
    );

    return apiCeoStaffPaItems.groups
      .flatMap(group =>
        group.items.map(item => ({
          label: item.name,
          value: item.id.toString(),
          disabled: agendaItemsIds?.includes(item.id) // disabled is true if item is on the board
        }))
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [apiCeoStaffPaItems, agendaItems]);

  const { status: importStatus, mutate: importAgendaItem } = useMutation(
    postImportedAgendaItems,
    {
      onMutate: () => {
        overrideStatus("importAgendaItem", "loading");
      },
      onSuccess: () => {
        refreshGrid().then(() => {
          navigate(pages.grid.edit.go());
          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("importAgendaItem", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("importAgendaItem", undefined);
      }
    }
  );

  //initialize modal on open/close
  useEffect(() => {
    if (!mhResult.enabled) {
      setTimeout(() => {
        setImportedAgendaItems(initialItem);
        containerUtils.resetValidation();
      }, 500);
      return;
    }

    const newItem = produce(initialItem, draftState => {
      const focus = params.focus;
      const value = params.value;

      draftState.focus = focus;
      draftState.value = value;
    });

    setImportedAgendaItems(newItem);
  }, [mhResult.enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const { status, overrideStatus } = useStatusManager(importStatus);

  const onImport = () => {
    containerUtils.triggerValidation().then(result => {
      if (!result.isValid) {
        return;
      }
    });
    importAgendaItem(importedAgendaItems);
    setImportedAgendaItems(initialItem);
  };

  const isSaving = importStatus === "loading";

  const onClose = () => {
    if (isSaving) {
      return;
    }

    navigate(pages.grid.edit.go());
  };

  const footerButtons = (
    <div className={`${namespace}-footer`}>
      <Button
        text="Cancel"
        type="secondary"
        onClick={() => {
          navigate(pages.grid.edit.go());
        }}
        disabled={isSaving}
      />
      <Button
        text="Import"
        type="primary"
        onClick={onImport}
        disabled={isSaving || importedAgendaItems.agendaItemIds.length === 0}
      />
    </div>
  );

  return (
    <PaModal
      title="Import Agenda Item"
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={onClose}
      className={namespace}
      footer={footerButtons}
    >
      <div className={`${namespace}-content`}>
        Import agenda items from CEO Staff Prioritized Agenda into this one.
        Select the agenda item you want to import. Only items you have
        permission to access will be available.
        <Select
          label={
            importedAgendaItems.agendaItemIds.length > 1
              ? "Agenda Items"
              : "Agenda Item"
          }
          mode="multiple"
          loading={apiCeoStaffPaItemsStatus === "loading"}
          autoUpdater={{
            item: importedAgendaItems,
            setItem: setImportedAgendaItems,
            propExpression: ri => ri.agendaItemIds
          }}
          validator={fieldUtils}
          options={CEOStaffPaItems}
          placeholder="Select"
          disabled={
            !!apiCeoStaffPaItems && apiCeoStaffPaItemsStatus !== "success"
          }
          labelSearch={true}
        />
      </div>
    </PaModal>
  );
};

export default ImportAgendaItemModal;
