import React from "react";
import "./Tooltip.scss";
import { InfoIcon } from "@opsdti-global-component-library/amgen-design-system";
import { Popover } from "antd";
import classNames from "classnames";

const namespace = "rts-pa-tooltip";

type Props = {
  tooltipIcon?: JSX.Element;
  children: JSX.Element | null | undefined;
  trigger?: "click" | "hover";
  placement?: "left" | "right" | "top" | "bottom";
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

export const Tooltip = (props: Props) => {
  const className = classNames(namespace, {
    [`${namespace}-click`]: props.trigger === "click"
  });

  if (!props.children) {
    return <></>;
  }

  return (
    <Popover
      placement={props.placement}
      content={props.children}
      trigger={props.trigger}
      className={className}
      open={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      {props.tooltipIcon || (
        <InfoIcon color="secondary" width={16} height={16} />
      )}
    </Popover>
  );
};

export default Tooltip;
