import React, { useState } from "react";
import { AdvancedTooltip as AdvancedTooltipType } from "api";
import AdvancedTooltipContent from "./AdvancedTooltipContent";
import { Tooltip } from "common-components/tooltip/Tooltip";

type Props = {
  className?: string;
  tooltipIcon?: JSX.Element;
  tooltip: AdvancedTooltipType | null;
  trigger?: "hover" | "click";
  placement?: "left" | "right" | "top" | "bottom";
  width?: "small" | "normal";
  closeIcon?: boolean;
};
export const AdvancedTooltip = (props: Props) => {
  const [open, setOpen] = useState(false);

  if (props.tooltip === null) {
    return <></>;
  }

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      placement={props.placement}
      tooltipIcon={props.tooltipIcon}
      trigger={props.trigger}
      isOpen={open}
      onOpenChange={handleOpenChange}
    >
      <AdvancedTooltipContent
        className={props.className}
        tooltip={props.tooltip}
        width={props.width}
        closeIcon={props.closeIcon}
        onClose={handleClose}
      />
    </Tooltip>
  );
};
