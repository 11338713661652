import React, { useContext, useEffect, useMemo, useState } from "react";
import "./UpsertCalendarModal.scss";
import {
  getCalendar,
  postCalendar,
  putCalendar,
  deleteCalendar as deleteCalendarApi,
  Calendar,
  AdEntity,
  getAuthorization,
  EntityListItem,
  MilestoneCalendarKeysResponse,
  getMilestoneCalendarKeys
} from "api";
import PaModal from "common-components/pa-modal/PaModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Tabs,
  Popconfirm
} from "@opsdti-global-component-library/amgen-design-system";
import { useValidator } from "global/use-validator";
import { useStatusManager } from "global/use-status-manager";
import TrashIcon from "icons/trash-icon";
import { useNavigate } from "react-router-dom";
import { useModalHelper } from "global/use-modal-helper";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import {
  Input,
  DatePicker,
  Select,
  Checkbox,
  DateRangePicker
} from "common-components";
import { useGetAllAdEntities } from "global/use-get-all-ad-entities";
import { getKeyEventsId } from "utils/multitenancy";
import dayjs from "dayjs";
import produce from "immer";
import { useOktaUser } from "global/use-okta-user";
import { tooltips } from "./upsertCalendarModalTooltips";
import UpsertCalendarModalCalendarKeysContainer from "./calendar-keys-container/UpsertCalendarModalCalendarKeysContainer";
import { DangerIcon } from "@gitlab-rtsensing/component-library";
import {
  getQuarterEnd,
  getQuarterStart
} from "components/key-events-calendar/calendarUtils";

type CalendarModel = {
  id: number;
  title: string;
  publishedDate: string;
  owner: string;
  isHiddenFromSuperAdmin: boolean;
  admins: string[];
  fullViewers: string[];
  limitedViewers: string[];
  milestoneCalendarKeys: MilestoneCalendarKeysResponse;
  defaultDateRange: string[] | undefined[];
};

const initialCalendar: CalendarModel = {
  id: 0,
  title: "",
  publishedDate: dayjs().toISOString(),
  owner: "",
  isHiddenFromSuperAdmin: false,
  admins: [],
  fullViewers: [],
  limitedViewers: [],
  milestoneCalendarKeys: { keys: [] },
  defaultDateRange: []
};

type AddMode = {
  text: "Add New";
  isAdd: true;
  isEdit: false;
};

type EditMode = {
  text: "Edit";
  isAdd: false;
  isEdit: true;
};

type ModalMode = AddMode | EditMode;

export type Props = {
  onDateRangeChange: (dateRange: string[] | undefined[]) => void;
};

const namespace = "rts-pa-upsert-calendar-modal";

const UpsertCalendarModal = (props: Props) => {
  const perms = useContext(PermissionsContext);
  const navigate = useNavigate();
  const mhResult = useModalHelper(
    [
      pages.keyEvents.addCalendarModal.path,
      pages.keyEvents.editCalendarModal.path
    ],
    [perms.keyEvents.create, perms.keyEvents.edit]
  );
  const currentUser = useOktaUser();
  const [calendar, setCalendar] = useState<CalendarModel>(initialCalendar);
  const [dateRangeOnOpen, setDateRangeOnOpen] = useState<
    string[] | undefined[]
  >([undefined, undefined]);

  const mode: ModalMode = useMemo(() => {
    if (mhResult.path === pages.keyEvents.addCalendarModal.path) {
      return {
        text: "Add New",
        isAdd: true,
        isEdit: false
      };
    } else {
      return {
        text: "Edit",
        isAdd: false,
        isEdit: true
      };
    }
  }, [mhResult]);

  const { containerUtils, fieldUtils } = useValidator();

  const { refetch: refreshPermsList } = useQuery(
    ["authorization"],
    getAuthorization,
    {
      enabled: false
    }
  );

  const {
    data: apiMilestoneCalendarKeys,
    refetch: refreshMilestoneCalendarKeyLegend
  } = useQuery(["calendar-keys"], getMilestoneCalendarKeys, { enabled: false });

  const adEntities = useGetAllAdEntities();

  const {
    data,
    status: apiCalendarStatus,
    refetch: refreshApiCalendar,
    error
  } = useQuery(["calendar", getKeyEventsId()], getCalendar, {
    enabled: mhResult.enabled && mode.isEdit
  });

  const { status: addStatus, mutate: addCalendar } = useMutation(postCalendar, {
    onMutate: () => {
      overrideStatus("addCalendar", "loading");
    },
    onSuccess: () => {
      refreshPermsList().then(query => {
        const newCalendar: EntityListItem | undefined =
          query.data?.ke.calendarList.find(a => a.title === calendar?.title);

        if (newCalendar) {
          navigate(pages.keyEvents.go(newCalendar.id.toString()));
        } else {
          navigate(pages.keyEvents.go());
        }

        setTimeout(() => {
          //keep spinner going when closing modal
          overrideStatus("addCalendar", undefined);
        }, 500);
      });
    },
    onError: () => {
      overrideStatus("addCalendar", undefined);
    }
  });
  const { status: updateStatus, mutate: updateCalendar } = useMutation(
    putCalendar,
    {
      onMutate: () => {
        overrideStatus("putCalendar", "loading");
      },
      onSuccess: () => {
        Promise.all([
          refreshPermsList(),
          refreshApiCalendar(),
          refreshMilestoneCalendarKeyLegend()
        ]).then(() => {
          if (getKeyEventsId() !== "0") {
            // 0 when redirected by permissions to pa-grid or unauthorized on removing self from calendar
            navigate(pages.keyEvents.go());
          }

          setTimeout(() => {
            //keep spinner going when closing modal -- started on update
            overrideStatus("putCalendar", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("putCalendar", undefined);
      }
    }
  );
  const { status: deleteStatus, mutate: deleteCalendar } = useMutation(
    deleteCalendarApi,
    {
      onMutate: () => {
        overrideStatus("deleteCalendar", "loading");
      },
      onSuccess: () => {
        refreshPermsList().then(() => {
          if (getKeyEventsId() !== "0") {
            // 0 when deleting last calendar for self, and redirected by permissions to pa-grid or unauthorized page
            navigate(pages.keyEvents.go());
          }

          setTimeout(() => {
            //keep spinner going when closing modal
            overrideStatus("deleteCalendar", undefined);
          }, 500);
        });
      },
      onError: () => {
        overrideStatus("deleteCalendar", undefined);
      }
    }
  );

  const { status, overrideStatus } = useStatusManager(
    mode.isEdit && mhResult.enabled ? apiCalendarStatus : "idle",
    addStatus,
    updateStatus,
    deleteStatus
  );

  //reset modal on close
  useEffect(() => {
    if (mhResult.enabled && mode.isEdit && data) {
      const mapToStrings = (adEntities: AdEntity[]): string[] =>
        adEntities.map(a => a.username);

      const owner =
        adEntities.users.find(u => u.username === data.owner.username)
          ?.displayName || "";

      const calendar: CalendarModel = {
        id: data.id,
        title: data.title,
        publishedDate: data.publishedDate,
        owner: owner,
        isHiddenFromSuperAdmin: data.isHiddenFromSuperAdmin,
        admins: mapToStrings(data.admins),
        fullViewers: mapToStrings(data.fullViewers),
        limitedViewers: mapToStrings(data.limitedViewers),
        milestoneCalendarKeys: data.milestoneCalendarKeys,
        defaultDateRange: data.defaultDateRange
      };
      setCalendar(calendar);
    } else if (mhResult.enabled && mode.isAdd) {
      const newCalendar = produce(calendar, draftState => {
        const owner =
          adEntities.users.find(u => u.email === currentUser?.email)
            ?.displayName || "";

        draftState.owner = owner;
        draftState.milestoneCalendarKeys =
          apiMilestoneCalendarKeys || calendar.milestoneCalendarKeys;
      });
      setCalendar(newCalendar);
    } else if (!mhResult.enabled) {
      setTimeout(() => {
        containerUtils.resetValidation();
        setCalendar(initialCalendar);
      }, 500);
    }
  }, [mhResult.enabled, data, adEntities, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    containerUtils.triggerValidation().then(({ isValid }) => {
      if (!isValid) {
        return;
      }

      const mapToAdEntities = (opts: string[]): AdEntity[] => {
        let newAdEntities = opts.map(optVal => {
          let adEntity: AdEntity | undefined = adEntities.users.find(
            u => u.username === optVal
          );

          if (!adEntity) {
            adEntity = adEntities.groups.find(u => u.username === optVal);
          }

          // should never happen
          if (!adEntity) {
            return undefined as unknown as AdEntity;
          }

          return adEntity;
        });

        //remove undefined items
        newAdEntities = newAdEntities.filter(e => !!e);

        return newAdEntities;
      };

      const updateDates = (
        dates: string[] | undefined[]
      ): string[] | undefined[] => {
        if (dates[0] && dates[1]) {
          const startDate = getQuarterStart(dayjs(dates[0]));
          const endDate = getQuarterEnd(dayjs(dates[1]));
          if (startDate && endDate) {
            const updatedDates: string[] = [
              startDate.format("YYYY-MM-DD"),
              endDate.format("YYYY-MM-DD")
            ];
            return updatedDates;
          }
        }
        return [undefined, undefined];
      };

      if (dateRangeOnOpen !== calendar.defaultDateRange) {
        const newDates = updateDates(calendar.defaultDateRange);
        props.onDateRangeChange(newDates);
        setDateRangeOnOpen(newDates);
      }

      const apiCalendar: Calendar = {
        id: calendar.id,
        title: calendar.title,
        publishedDate: dayjs
          .tz(dayjs(calendar.publishedDate).format("YYYY-MM-DD"))
          .toISOString(),
        owner: {} as AdEntity,
        isHiddenFromSuperAdmin: calendar.isHiddenFromSuperAdmin,
        admins: mapToAdEntities(calendar.admins),
        fullViewers: mapToAdEntities(calendar.fullViewers),
        limitedViewers: mapToAdEntities(calendar.limitedViewers),
        milestoneCalendarKeys: calendar.milestoneCalendarKeys,
        defaultDateRange: updateDates(calendar.defaultDateRange)
      };

      if (mode.isAdd) {
        addCalendar(apiCalendar);
      } else if (mode.isEdit) {
        updateCalendar(apiCalendar);
      }
    });
  };

  const isSaving = status === "loading";
  const canDelete = ![1, 2].includes(calendar.id);

  const onClose = () => {
    if (isSaving) {
      return;
    }

    navigate(pages.keyEvents.go());
  };

  let footerButtons: JSX.Element;
  const footerClassName = `${namespace}-footer${canDelete ? "" : "-no-delete"}`;
  if (mode.isEdit) {
    footerButtons = (
      <div className={footerClassName}>
        {canDelete && (
          <Popconfirm
            title="This calendar will be deleted"
            content="Please note that when you delete a calendar, it will be deleted and will not be retrievable."
            okText="CONFIRM"
            cancelText="CANCEL"
            icon={<DangerIcon className={`${namespace}-icon`} />}
            onConfirm={deleteCalendar}
          >
            <Button
              text="Delete Calendar"
              type="secondary"
              icon={<TrashIcon />}
              disabled={isSaving}
              danger
            />
          </Popconfirm>
        )}
        <Popconfirm
          title="You are about to publish your updates"
          content="Please know that once you publish your updates, they will be live for those who have access to view. "
          okText="CONFIRM"
          cancelText="CANCEL"
          onConfirm={onSave}
        >
          <Button text="Publish Updates" type="primary" disabled={isSaving} />
        </Popconfirm>
      </div>
    );
  } else {
    //mode.isAdd
    footerButtons = (
      <div className={footerClassName}>
        <Button
          text="Cancel"
          type="secondary"
          onClick={() => {
            navigate(pages.keyEvents.go());
          }}
          disabled={isSaving}
        />
        <Popconfirm
          title="You are about to publish your calendar"
          content="Please know that once you publish your calendar, it will be live for those who have access to view. "
          okText="CONFIRM"
          cancelText="CANCEL"
          onConfirm={onSave}
        >
          <Button text="Save" type="primary" disabled={isSaving} />
        </Popconfirm>
      </div>
    );
  }

  return (
    <PaModal
      title={`${mode.text} ${calendar.title} Forward 12 Quarters`}
      status={status}
      axiosErrors={error}
      isOpen={mhResult.enabled}
      onClose={onClose}
      disableBackdropDismiss={true}
      className={namespace}
      footer={footerButtons}
    >
      <div className={`${namespace}-content`}>
        <Input
          label="Title"
          placeholder="Input"
          required
          autoUpdater={{
            item: calendar,
            setItem: setCalendar,
            propExpression: x => x.title
          }}
          validator={fieldUtils}
          tooltip={tooltips.title}
        />
        <div className={`${namespace}-one-line-container`}>
          <DatePicker
            label='"As Of" Date'
            placeholder="Select date"
            required
            autoUpdater={{
              item: calendar,
              setItem: setCalendar,
              propExpression: x => x.publishedDate
            }}
            tooltip={tooltips.publishedDate}
          />
          <Input
            label="Owner"
            disabled={true}
            autoUpdater={{
              item: calendar,
              setItem: setCalendar,
              propExpression: x => x.owner
            }}
            tooltip={tooltips.owner}
          />
        </div>
        <DateRangePicker
          label="Set Default Timeframe"
          autoUpdater={{
            item: calendar,
            setItem: setCalendar,
            propExpressionStartDate: x => x.defaultDateRange[0],
            propExpressionEndDate: x => x.defaultDateRange[1]
          }}
          tooltip={tooltips.defaultDateRange}
        />
        <Tabs
          items={[
            {
              key: "calendar-user-groups",
              label: "User Groups",
              children: (
                <div className={`${namespace}-tab-children-container`}>
                  <Checkbox
                    label="Hide from Super Admins?"
                    autoUpdater={{
                      item: calendar,
                      setItem: setCalendar,
                      propExpression: x => x.isHiddenFromSuperAdmin
                    }}
                  />
                  <Select
                    label="Admin Users & Groups"
                    options={adEntities.options}
                    autoUpdater={{
                      item: calendar,
                      setItem: setCalendar,
                      propExpression: x => x.admins
                    }}
                    mode="multiple"
                    tooltip={tooltips.admins}
                    placeholder="Select"
                    labelSearch={true}
                  />
                  <Select
                    label="Full View Users & Groups"
                    options={adEntities.options}
                    autoUpdater={{
                      item: calendar,
                      setItem: setCalendar,
                      propExpression: x => x.fullViewers
                    }}
                    mode="multiple"
                    tooltip={tooltips.fullViewers}
                    placeholder="Select"
                    labelSearch={true}
                  />
                  <Select
                    label="Limited View Users & Groups"
                    options={adEntities.options}
                    autoUpdater={{
                      item: calendar,
                      setItem: setCalendar,
                      propExpression: x => x.limitedViewers
                    }}
                    mode="multiple"
                    tooltip={tooltips.limitedViewers}
                    placeholder="Select"
                    labelSearch={true}
                  />
                </div>
              )
            },
            {
              key: "calendar-milestone-keys",
              label: "Calendar Keys",
              children: (
                <UpsertCalendarModalCalendarKeysContainer
                  autoUpdater={{
                    item: calendar,
                    setItem: setCalendar,
                    propExpression: x => x.milestoneCalendarKeys.keys
                  }}
                  validator={fieldUtils}
                />
              )
            }
          ]}
        />
      </div>
    </PaModal>
  );
};

export default UpsertCalendarModal;
