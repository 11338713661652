import { Array, Record, Static, String } from "runtypes";
import {
  InactiveSectionValidator,
  LinkValidator,
  OwnersValidator,
  StatusValidator,
  AdvancedTooltipValidator
} from "../common";
import {
  NextApprovalLaunchE2lValidator,
  NextPriorityMilestoneValidator,
  PipelineStudyEnrollmentValidator
} from "api/pipeline/pipeline-project";

const CommercialPipelineProjectValidator = Record({
  title: String,
  status: StatusValidator,
  tooltip: AdvancedTooltipValidator.nullable().optional(),
  owners: OwnersValidator.nullable(),
  nextApprovalLaunchE2l: NextApprovalLaunchE2lValidator.nullable(),
  nextPriorityMilestone: NextPriorityMilestoneValidator.nullable(),
  studyEnrollments: Array(PipelineStudyEnrollmentValidator),
  externalLinks: Array(LinkValidator)
});

export type CommercialPipelineProject = Static<
  typeof CommercialPipelineProjectValidator
>;

export const CommercialPipelineSectionValidator = Record({
  projects: Array(
    CommercialPipelineProjectValidator.Or(InactiveSectionValidator)
  )
});

export type CommercialPipelineSection = Static<
  typeof CommercialPipelineSectionValidator
>;
