import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import "./PageAlert.scss";
import { getWorkstreamConstants } from "api/workstreamConstant";
import { Alert } from "@opsdti-global-component-library/amgen-design-system";

type Props = {
  page: "prioritized-agenda" | "key-events";
};

const namespace = "rts-pa-alert";

const PageAlert = (props: Props) => {
  const [msg, setMsg] = useState<string>();
  const { data } = useQuery(
    [`pa-constants-${props.page}`],
    () => getWorkstreamConstants("page-info-banner", props.page)
  );
  
  useEffect(() => {
    setMsg(data?.constants[0]?.value ?? undefined)
  },[data])

  return msg ? (
    <div className={namespace}>
      <Alert type="info" content={msg} />
    </div>
  ) : (
    <></>
  );
};

export default PageAlert;
