import { AdvancedTooltip } from "api";

const title: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "Please enter an official name for your Calendar. This will be the display title visible to all users & viewers."
          ]
        }
      ]
    }
  ]
};

const publishedDate: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            'Please enter an official "as of" date for your Calendar. You will be able to modify this in the future, and it will be visible to all users & viewers.'
          ]
        }
      ]
    }
  ]
};

const owner: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "The creator of the Calendar. Automatically receives Admin privileges."
          ]
        }
      ]
    }
  ]
};

const admins: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "Enter users that should have administrative rights to make direct changes to this Calendar. These users will be able to edit, modify and delete contents. Please enter individual Amgen email addresses or an active Amgen AD group address.​"
          ]
        }
      ]
    }
  ]
};

const fullViewers: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "Enter users that should be able to view all items and milestones on this Calendar. Please enter individual Amgen email addresses or an active Amgen AD group address."
          ]
        }
      ]
    }
  ]
};

const limitedViewers: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          content: [
            "Enter users that should be able to view limited items on the Calendar, NOT able to view items and milestones hidden from limited viewers. Please enter individual Amgen email addresses or an active Amgen AD group address."
          ]
        }
      ]
    }
  ]
};

const defaultDateRange: AdvancedTooltip = {
  sections: [
    {
      rows: [
        {
          label: "Set Default Timeframe",
          content: []
        },
        {
          content: [
            "Set a custom default timeframe that will display automatically when this page loads. This setting overrides the system's default timeframe of 12 quarters. A user can still filter the timeframe within the calendar view. Please note that all timeframes will default to the quarter they are in."
          ]
        }
      ]
    }
  ]
}

export const tooltips = {
  title: title,
  publishedDate: publishedDate,
  owner: owner,
  admins: admins,
  fullViewers: fullViewers,
  limitedViewers: limitedViewers,
  defaultDateRange: defaultDateRange
};
