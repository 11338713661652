import React from "react";
import "./KeyEventsCalendarEmptyContainer.scss";
import { Empty } from "@opsdti-global-component-library/amgen-design-system";
import { Loader } from "@gitlab-rtsensing/component-library";
import { useQuery } from "@tanstack/react-query";
import { useStatusManager } from "global/use-status-manager";
import { getMilestoneCalendarKeys } from "api";
import { CalendarFilters } from "../calendarTypes";
import { useCalendarContext } from "pages/key-events/CalendarContext";

export const namespace = "rts-pa-key-events-calendar-empty-container";

type Props = {
  filters: CalendarFilters;
};

export default function KeyEventsCalendarEmptyContainer(
  props: Props
): JSX.Element {
  const { calendarBoardStatus } = useCalendarContext();

  const { status: calendarKeysStatus } = useQuery(
    ["calendar-keys"],
    getMilestoneCalendarKeys,
    { enabled: false }
  );

  const { status } = useStatusManager(calendarBoardStatus, calendarKeysStatus);

  return (
    <div className={namespace}>
      {status === "loading" ? <Loader /> : <Empty description="No Data" />}
    </div>
  );
}
