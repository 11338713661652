import React from "react";
import "./KeyEventsCalendarRowMilestoneRange.scss";
import { MilestoneRange } from "components/key-events-calendar/calendarTypes";
import { Text } from "common-components";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";

export const namespace = "rts-pa-key-events-calendar-row-milestone-range";

type Props = {
  milestone: MilestoneRange;
  isFaded?: boolean;
};

export default function KeyEventsCalendarRowMilestoneRange(
  props: Props
): JSX.Element {
  let endYear = props.milestone.endYear;
  let endMonth = props.milestone.endMonth + 1;

  if (endMonth >= 13) {
    endYear++;
    endMonth = 1;
  }

  const milestone = (
    <div
      className={`${namespace} ${props.isFaded && `${namespace}-faded`}`}
      style={{
        gridColumn: `y${props.milestone.startYear}m${props.milestone.startMonth} / y${endYear}m${endMonth}`
      }}
    >
      <div
        id={`milestone_${props.milestone.id}`}
        className={`${namespace}-container`}
      >
        <Text className={`${namespace}-container-text`}>
          {props.milestone.name}
        </Text>
      </div>
    </div>
  );

  return (
    <>
      {props.milestone ? (
        <AdvancedTooltip
          tooltip={props.milestone.tooltip}
          tooltipIcon={milestone}
          trigger="click"
          closeIcon={true}
        />
      ) : (
        milestone
      )}
    </>
  );
}
