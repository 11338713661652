import { Array, Record, Static, String } from "runtypes";

export const WorkstreamConstantValidator = Record({
  fullKey: String.nullable(),
  key: String,
  category: String,
  workstream: String.nullable(),
  value: String
});

export const WorkstreamConstantsValidator = Record({
  constants: Array(WorkstreamConstantValidator)
});

export type WorkstreamConstant = Static<typeof WorkstreamConstantValidator>;

export type WorkstreamConstants = Static<typeof WorkstreamConstantsValidator>;
