import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { ActivityLog as NotificationsActivityLog } from "@gitlab-rtsensing-notification-widgets/amgen-notification-widgets";
import { ActivityLogFilterObject } from "@gitlab-rtsensing-notification-widgets/amgen-notification-widgets/lib/types";
import { ActivityLogDataPayload } from "api/activity-log";
import useLimitedView from "global/use-limited-view";
import "./BaseActivityLog.scss";
import {
  ActivityLogData,
  ActivityLogDropdownValuesData
} from "api/notifications/activity-log";

type BaseActivityLogProps = {
  workstream: string;
  activityType: string;
  namespace: string;
  hasPerms: boolean;
  defaultActivityLogFilters: ActivityLogDataPayload;
  getDataFn: (payload: ActivityLogDataPayload) => Promise<ActivityLogData>;
  getDropdownDataFn: (payload: {
    workstream: string;
    activityType: string;
  }) => Promise<ActivityLogDropdownValuesData>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  isLimitedViewPage: any;
  defaultCollapsed?: boolean;
};

export const BaseActivityLog = ({
  workstream,
  activityType,
  namespace,
  hasPerms,
  defaultActivityLogFilters,
  getDataFn,
  getDropdownDataFn,
  isLimitedViewPage,
  defaultCollapsed
}: BaseActivityLogProps) => {
  const [filtersSelection, setFiltersSelection] =
    useState<ActivityLogDataPayload>(defaultActivityLogFilters);

  const [isFirstDataLoad, setIsFirstDataLoad] = useState(true);

  const onFilterValueChange = (
    activityLogFilters: ActivityLogFilterObject[]
  ) => {
    setFiltersSelection(prevPayload => ({
      ...prevPayload,
      filterList: activityLogFilters.map(item => ({
        filterKey: item.filterKey,
        filterValue: item.filterValue
      }))
    }));
  };

  const { data: dropdownOptions, isLoading: isDropdownLoading } = useQuery(
    ["activityLogDropdownOptions", workstream, activityType],
    () => getDropdownDataFn({ workstream, activityType }),
    {
      enabled: hasPerms
    }
  );

  const {
    data: activityLogData,
    refetch,
    isLoading: isActivityLogDataLoading
  } = useQuery(
    ["activityLogData", filtersSelection],
    () => getDataFn(filtersSelection),
    {
      enabled: hasPerms,
      cacheTime: 0
    }
  );

  const { isLimitedView } = useLimitedView(isLimitedViewPage);

  useEffect(() => {
    if (hasPerms) {
      refetch();
    }
  }, [filtersSelection, refetch, hasPerms]);

  useEffect(() => {
    if (isFirstDataLoad && !isActivityLogDataLoading) {
      setIsFirstDataLoad(false);
    }
  }, [isActivityLogDataLoading, isFirstDataLoad]);

  // Handle removing duplicates if needed
  useEffect(() => {
    if (!dropdownOptions) {
      return;
    }

    if (dropdownOptions.dropdownValues?.item) {
      dropdownOptions.dropdownValues.item = Array.from(
        new Set(dropdownOptions.dropdownValues.item)
      );
    }
  }, [dropdownOptions]);

  return (
    <PermissionsWrapper permissions={hasPerms && !isLimitedView}>
      <div className={`${namespace}-container`}>
        <NotificationsActivityLog
          defaultCollapsed={defaultCollapsed}
          title="Recent Updates"
          type={activityType}
          workstream={workstream}
          timelineSize={3}
          loading={isFirstDataLoad}
          drawerLoading={isActivityLogDataLoading || isDropdownLoading}
          aiSummary={activityLogData?.aiSummary}
          timelineData={activityLogData?.timelineData || []}
          dropdownMenuOptions={dropdownOptions}
          onFilterChange={onFilterValueChange}
        />
      </div>
    </PermissionsWrapper>
  );
};
